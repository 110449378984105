import * as React from 'react';

import { NotificationToastRendererT } from 'common/components/toasts/NotificationToastsManager/models';
import {
    NotificationLinkFactoryT,
    NotificationRendererT,
} from 'common/components/notifications/NotificationsBarContent/models';
import {
    NotificationActionEnum,
    NotificationEmotionEnum,
    NotificationIconEnum,
} from 'common/store/notifications/models';
import StatusChangedCard from 'common/components/notification-cards/StatusChangedCard/StatusChangedCard';
import OrderColoredStatus from 'common/components/status-colored/OrderColoredStatus/OrderColoredStatus';
import { SubjectTypeEnum } from 'common/utils/api/models';
import {
    ALERT_ICON_LOAD_STATUS_MAP,
    ALERT_ICON_ORDER_DRAFT_STATUS_MAP,
    ALERT_ICON_ORDER_STATUS_MAP,
    ALERT_ICON_TOUR_STATUS_MAP,
    ALERT_ICON_TRANSPORTATION_STATUS_MAP,
    EMOTION_LOAD_STATUS_MAP,
    EMOTION_ORDER_DRAFT_STATUS_MAP,
    EMOTION_ORDER_STATUS_MAP,
    EMOTION_TOUR_STATUS_MAP,
    EMOTION_TRANSPORTATION_STATUS_MAP,
} from 'common/components/toasts/utils';
import ShipmentColoredStatus from 'common/components/status-colored/ShipmentColoredStatus/ShipmentColoredStatus';
import TourColoredStatus from 'common/components/status-colored/TourColoredStatus/TourColoredStatus';
import TransportOrderColoredStatus from 'common/components/status-colored/TransportOrderColoredStatus/TransportOrderColoredStatus';
import { urlFactory } from 'shipper/utils/urls';
import { urlFactory as commonURLFactory, getRePath } from 'common/utils/urls';
import { AlertToastRendererT } from 'common/components/toasts/AlertToastsManager/models';
import { AnyAlert } from 'common/store/alerts/models';
import { ShipperAnyAlert } from './models';
import RFQColoredStatus from 'common/components/status-colored/RFQColoredStatus/RFQColoredStatus';
import ExpiredDocumentCard from 'common/components/notification-cards/ExpiredDocumentCard/ExpiredDocumentCard';
import ExpireSoonDocumentCard from 'common/components/notification-cards/ExpireSoonDocumentCard/ExpireSoonDocumentCard';
import UpdatedDocumentCard from 'common/components/notification-cards/UpdatedDocumentCard/UpdatedDocumentCard';
import RevokedDocumentCard from 'common/components/notification-cards/RevokedDocumentCard/RevokedDocumentCard';
import UpdatedContractCard from 'common/components/notification-cards/UpdatedContractCard/UpdatedContractCard';
import RevokedContractCard from 'common/components/notification-cards/RevokedContractCard/RevokedContractCard';
import ExpiredContractCard from 'common/components/notification-cards/ExpiredContractCard/ExpiredContractCard';
import ExpireSoonContractCard from 'common/components/notification-cards/ExpireSoonContractCard/ExpireSoonContractCard';
import CompletedDocumentCard from 'common/components/notification-cards/CompletedDocumentCard/CompletedDocumentCard';
import CompletedContractCard from 'common/components/notification-cards/CompletedContractCard/CompletedContractCard';
import UpdatedShipperContractLaneCard from 'common/components/notification-cards/UpdatedShipperContractLaneCard/UpdatedShipperContractLaneCard';
import RevokedShipperContractLaneCard from 'common/components/notification-cards/RevokedShipperContractLaneCard/RevokedShipperContractLaneCard';
import ExpiredShipperContractLaneCard from 'common/components/notification-cards/ExpiredShipperContractLaneCard/ExpiredShipperContractLaneCard';
import CompletedShipperContractLaneCard from 'common/components/notification-cards/CompletedShipperContractLaneCard/CompletedShipperContractLaneCard';
import ExpireSoonShipperContractLaneCard from 'common/components/notification-cards/ExpireSoonShipperContractLaneCard/ExpireSoonShipperContractLaneCard';
import UploadDocumentCard from 'common/components/notification-cards/UploadDocumentCard/UploadDocumentCard';
import UploadShipperContractLaneCard from 'common/components/notification-cards/UploadShipperContractLaneCard/UploadShipperContractLaneCard';
import UploadContractCard from 'common/components/notification-cards/UploadContractCard/UploadContractCard';

export const renderNotificationToast: NotificationToastRendererT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_LOAD_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.shipment}
                        actionStatus={<ShipmentColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_ORDER_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.order}
                        actionStatus={<OrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.tour}
                        actionStatus={<TourColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TRANSPORTATION_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.transportationOrder}
                        actionStatus={<TransportOrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.RFQ_ALERT: {
            const { data } = notification;

            const points = data?.points || [];
            const firstPointAddress = points[0]?.addressStr;
            const lastPointAddress = points[points.length - 1]?.addressStr;

            return {
                icon: data.status ? ALERT_ICON_ORDER_DRAFT_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.rfq}
                        subjectNumber={notification.subjectNumber}
                        originCity={firstPointAddress}
                        destinationCity={lastPointAddress}
                        actionType={SubjectTypeEnum.rfq}
                        actionStatus={<RFQColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_UPLOAD: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UploadDocumentCard documentType={data.documentType} />,
            };
        }

        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <RevokedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonDocumentCard documentType={data.documentType} />,
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UploadContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <RevokedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: (
                    <UploadShipperContractLaneCard
                        id={data.tztLaneId || '-'}
                        cityTo={data.cityTo || ''}
                        cityFrom={data.cityFrom || ''}
                    />
                ),
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: (
                    <UpdatedShipperContractLaneCard
                        id={data.tztLaneId || '-'}
                        cityFrom={data.cityFrom || ''}
                        cityTo={data.cityTo || ''}
                    />
                ),
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: (
                    <RevokedShipperContractLaneCard
                        id={data.tztLaneId || '-'}
                        cityFrom={data.cityFrom || ''}
                        cityTo={data.cityTo || ''}
                    />
                ),
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredShipperContractLaneCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedShipperContractLaneCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonShipperContractLaneCard id={data.tztLaneId || '-'} />,
            };
        }

        default: {
            return null;
        }
    }
};

export const renderNotification: NotificationRendererT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_LOAD_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.shipment}
                        actionStatus={<ShipmentColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_ORDER_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.order}
                        actionStatus={<OrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.tour}
                        actionStatus={<TourColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TRANSPORTATION_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.order}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.transportationOrder}
                        actionStatus={<TransportOrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.RFQ_ALERT: {
            const { data } = notification;

            const points = data?.points || [];
            const firstPointAddress = points[0]?.addressStr;
            const lastPointAddress = points[points.length - 1]?.addressStr;

            return {
                emotion: data.status ? EMOTION_ORDER_DRAFT_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.rfq}
                        subjectNumber={notification.subjectNumber}
                        originCity={firstPointAddress}
                        destinationCity={lastPointAddress}
                        actionType={SubjectTypeEnum.rfq}
                        actionStatus={<RFQColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <RevokedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedDocumentCard documentType={data.documentType} />,
            };
        }
        case NotificationActionEnum.DOCUMENT_UPLOAD: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UploadDocumentCard documentType={data.documentType} />,
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <RevokedContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UploadContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedContractCard contractName={data.contractName || ''} />,
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredShipperContractLaneCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedShipperContractLaneCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonShipperContractLaneCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: (
                    <RevokedShipperContractLaneCard
                        id={data.tztLaneId || '-'}
                        cityFrom={data.cityFrom || ''}
                        cityTo={data.cityTo || ''}
                    />
                ),
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: (
                    <UploadShipperContractLaneCard
                        id={data.tztLaneId || '-'}
                        cityTo={data.cityTo || ''}
                        cityFrom={data.cityFrom || ''}
                    />
                ),
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: (
                    <UpdatedShipperContractLaneCard
                        id={data.tztLaneId || '-'}
                        cityFrom={data.cityFrom || ''}
                        cityTo={data.cityTo || ''}
                    />
                ),
            };
        }

        default: {
            return null;
        }
    }
};

export const getNotificationLink: NotificationLinkFactoryT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED:
        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED:
        case NotificationActionEnum.ORDER_STATUS_CHANGED:
        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            return urlFactory.orderDetails(notification.subjectId || 'null');
        }
        case NotificationActionEnum.RFQ_ALERT: {
            return {
                pathname: urlFactory.rfqDetails(notification.data.id || 'null'),
                state: {
                    rePath: getRePath(),
                },
            };
        }
        case NotificationActionEnum.DOCUMENT_UPLOAD:
        case NotificationActionEnum.DOCUMENT_UPDATED:
        case NotificationActionEnum.DOCUMENT_REVOKED:
        case NotificationActionEnum.DOCUMENT_EXPIRED:
        case NotificationActionEnum.DOCUMENT_COMPLETED:
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            return urlFactory.documentDetails(notification.data.documentType);
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_UPLOADED:
        case NotificationActionEnum.SHIPPER_CONTRACT_UPDATED:
        case NotificationActionEnum.SHIPPER_CONTRACT_REVOKED:
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED:
        case NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED:
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON: {
            return commonURLFactory.shipperContractDetails({
                shipperContractId: notification.data.contractId || '-',
            });
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON: {
            return commonURLFactory.shipperContractLaneDetails({
                shipperContractId: notification.data.contractId || '-',
                shipperContractLaneId: notification.data.laneId || '-',
            });
        }
        default: {
            return null;
        }
    }
};

const checkIsShipperAnyAlert = (anyAlert: AnyAlert | null | undefined): anyAlert is ShipperAnyAlert => {
    return anyAlert instanceof ShipperAnyAlert;
};

export const renderAlertToast: AlertToastRendererT = (t, anyAlert) => {
    if (!checkIsShipperAnyAlert(anyAlert)) {
        return null;
    }

    // TODO
    return null;
};
