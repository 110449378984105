import { generatePath } from 'react-router';

import {
    OMSRoutesEnum,
    RFQRoutesEnum,
    ProfileRoutesEnum,
    ProfileDocumentsRoutesEnum,
    CommonRoutesEnum,
} from 'common/constants';
import { ApiDocumentTypeT } from 'common/utils/api/models';
import qs from 'qs';
import { CommonSidebarsTypeEnum, SidebarQueryKeysEnum } from 'common/layouts/SideBars/models';
import { JSONArrayParam } from 'common/utils/query';
import { DocumentDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/models';
import { getRePath } from 'common/utils/urls';

export const urlFactory = {
    orderList: () => {
        return CommonRoutesEnum.orders;
    },
    orderDetails: (orderId: OrderIdT) => {
        return {
            pathname: generatePath(OMSRoutesEnum.orderDetails, {
                orderId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    rfqDetails: (rfqId: RFQIdT) => {
        return generatePath(RFQRoutesEnum.details, {
            rfqId,
        });
    },
    rfqList: () => {
        return RFQRoutesEnum.list;
    },
    profileBasic: () => {
        return ProfileRoutesEnum.basic;
    },
    profileDocuments: () => {
        return ProfileRoutesEnum.documents;
    },
    optionalDocuments: () => {
        return ProfileDocumentsRoutesEnum.optionalDocuments;
    },
    shipperContracts: () => {
        return ProfileDocumentsRoutesEnum.shipperContracts;
    },
    documentDetails: (documentType: ApiDocumentTypeT) => {
        return `${ProfileDocumentsRoutesEnum.requiredDocuments}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<DocumentDetailsSidebarDataT>([
                {
                    type: CommonSidebarsTypeEnum.document,
                    documentType,
                    initIsEditing: false,
                },
            ]),
        })}`;
    },
};
