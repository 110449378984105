import {
    ApiOrderSourceT,
    OrderSortEnum,
    OrderSourceEnum,
    OrderSourceT,
    ShipperOrdersQuickFilterEnum,
    ApiOrdersSourceEnum,
} from 'common/utils/api/models';
import { FetchOrdersPageQueryT, FetchOrdersSortFieldT } from 'common/store/orders/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { PageSortT } from 'common/utils/query';

const getQueryFromQuickFilters = (
    quickFilter: ShipperOrdersQuickFilterEnum | null | undefined,
): FetchOrdersPageQueryT => {
    switch (quickFilter) {
        case ShipperOrdersQuickFilterEnum.placed: {
            return {
                statusBarPage: 'PLACED',
            };
        }
        case ShipperOrdersQuickFilterEnum.done: {
            return {
                statusBarPage: 'DONE',
            };
        }
        case ShipperOrdersQuickFilterEnum.withIssues: {
            return {
                statusBarPage: 'WITH_ISSUES',
            };
        }
        case ShipperOrdersQuickFilterEnum.inProgress: {
            return {
                statusBarPage: 'IN_PROGRESS',
            };
        }
        case ShipperOrdersQuickFilterEnum.delivered: {
            return {
                statusBarPage: 'DELIVERED',
            };
        }
        case ShipperOrdersQuickFilterEnum.cancelled: {
            return {
                statusBarPage: 'CANCELED',
            };
        }
        default: {
            return {
                statusBarPage: 'ALL',
            };
        }
    }
};

const sortFieldMap: Record<OrderSortEnum, FetchOrdersSortFieldT> = {
    [OrderSortEnum.cost]: 'COST',
    [OrderSortEnum.pickupDateFrom]: 'PICKUP_DATE_FROM',
    [OrderSortEnum.dropOffDateFrom]: 'DROP_OFF_DATE_FROM',
    [OrderSortEnum.createdDate]: 'CREATED_DATE',
};

const convertOrderSource = (source: OrderSourceT | undefined): ApiOrderSourceT | null => {
    if (!source) {
        return null;
    }

    switch (source) {
        case OrderSourceEnum.imported: {
            return ApiOrdersSourceEnum.imported;
        }
        case OrderSourceEnum.internal: {
            return ApiOrdersSourceEnum.internal;
        }
        default: {
            return null;
        }
    }
};
const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT | undefined,
    selectedSort: PageSortT<OrderSortEnum> | null,
): FetchOrdersPageQueryT => {
    const dictTrailerId = queryFilters?.[QueryFiltersKeysEnum.dictTrailerId];

    let hasSpotOrder: boolean | undefined = queryFilters?.[QueryFiltersKeysEnum.onlySpotOrders] || undefined;
    if (queryFilters?.[QueryFiltersKeysEnum.onlyContractedOrders]) {
        hasSpotOrder = false;
    }

    return {
        addedDateFrom: queryFilters?.[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        addedDateTo: queryFilters?.[QueryFiltersKeysEnum.createdDateTo] || undefined,
        dropOffDockingHoursTo: queryFilters?.[QueryFiltersKeysEnum.dropOffDateTo] || undefined,
        dropOffDockingHoursFrom: queryFilters?.[QueryFiltersKeysEnum.dropOffDateFrom] || undefined,
        pickupDockingHoursTo: queryFilters?.[QueryFiltersKeysEnum.pickupDateTo] || undefined,
        pickupDockingHoursFrom: queryFilters?.[QueryFiltersKeysEnum.pickupDateFrom] || undefined,
        destination: queryFilters?.[QueryFiltersKeysEnum.destination] || undefined,
        origin: queryFilters?.[QueryFiltersKeysEnum.origin] || undefined,
        dictTrailerId: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,
        createdBy: queryFilters?.[QueryFiltersKeysEnum.createdById] || undefined,
        textSearch: queryFilters?.[QueryFiltersKeysEnum.searchText] || undefined,

        hasSpotOrder,

        source: convertOrderSource(queryFilters?.[QueryFiltersKeysEnum.source]) || undefined,

        ...getQueryFromQuickFilters(queryFilters?.[QueryFiltersKeysEnum.quickFilter]),
        sorts: selectedSort
            ? [
                  {
                      direction: selectedSort.direction,
                      field: sortFieldMap[selectedSort.value],
                  },
              ]
            : undefined,
    };
};

export { prepareFetchPageQuery };
