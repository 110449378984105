import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { useTranslation } from 'react-i18next';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ArrowsIcon from 'common/icons/ArrowsIcon';

enum TypeEnum {
    all = 'all',
    spot = 'spot',
    contracted = 'contracted',
}

type OptionT = {
    id: TypeEnum;
    label: React.ReactNode;
};

export type PropsT = {
    className?: string;
    isSelectedOnlySpot?: boolean;
    isSelectedOnlyContracted?: boolean;
    onSelect: (value: { isSelectedOnlySpot?: boolean; isSelectedOnlyContracted?: boolean }) => void;
    testSelector?: string;
};

const i18nTMap: Record<TypeEnum, string> = {
    [TypeEnum.spot]: 'orders-table.filters.order-type.spot',
    [TypeEnum.contracted]: 'orders-table.filters.order-type.contract',
    [TypeEnum.all]: 'orders-table.filters.order-type.all',
};

const OrderTypeDropdown = (props: PropsT) => {
    const { className, isSelectedOnlySpot, isSelectedOnlyContracted, onSelect } = props;

    const { t } = useTranslation();

    const selectedValue = React.useMemo((): TypeEnum => {
        if (isSelectedOnlySpot) {
            return TypeEnum.spot;
        }

        if (isSelectedOnlyContracted) {
            return TypeEnum.contracted;
        }

        return TypeEnum.all;
    }, [isSelectedOnlySpot, isSelectedOnlyContracted]);

    const options = React.useMemo((): Array<OptionT> => {
        return [
            {
                id: TypeEnum.all,
                label: t(i18nTMap[TypeEnum.all]),
            },
            {
                id: TypeEnum.spot,
                label: t(i18nTMap[TypeEnum.spot]),
            },
            {
                id: TypeEnum.contracted,
                label: t(i18nTMap[TypeEnum.contracted]),
            },
        ];
    }, []);

    const handleChange = React.useCallback(
        (value: TypeEnum) => {
            onSelect({
                isSelectedOnlySpot: value === TypeEnum.spot,
                isSelectedOnlyContracted: value === TypeEnum.contracted,
            });
        },
        [onSelect],
    );

    return (
        <DropdownInput<OptionT, TypeEnum>
            className={className}
            selectedValue={selectedValue}
            options={options}
            onSelect={handleChange}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTrigger={(props, selectedOption) => {
                return (
                    <TransparentTrigger
                        testSelector="trigger"
                        onClick={props.onClick}
                        isPressed={props.isPressed}
                        label={t(i18nTMap[selectedOption?.id || TypeEnum.all])}
                        rightIcon={<ArrowsIcon />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                );
            }}
            getOptionValue={(option) => option?.id}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default OrderTypeDropdown;
