import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipmentDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import WarningTextFormatter from 'design-system/components/InfoTable/formatters/WarningTextFormatter/WarningTextFormatter';
import { formatTimeInterval } from 'common/utils/time';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import NumberIcon from 'common/icons/NumberIcon';
import ShipmentStatusPill from 'common/components/status-pill/ShipmentStatusPill/ShipmentStatusPill';
import CargoUnitIcon from 'common/icons/CargoUnitIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { getLoadUnitType } from 'common/utils/units';
import WeightIcon from 'common/icons/WeightIcon';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import TemperatureModeFormatter from 'design-system/components/InfoTable/formatters/TemperatureModeFormatter/TemperatureModeFormatter';
import CommentIcon from 'common/icons/CommentIcon';
import { OrderShipmentT, OrderWaypointT } from 'common/store/order-details/models';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import AttentionTextFormatter from 'design-system/components/InfoTable/formatters/AttentionTextFormatter/AttentionTextFormatter';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import ShipmentWarningPill from 'common/components/status-pill/ShipmentWarningPill/ShipmentWarningPill';
import { checkBrokerAppointmentStatus } from 'common/utils/broker-appointment';

const ADDRESS_SEPARATOR = ', ';

export type PropsT = {
    isReeferTrailer: boolean;
    shipmentNumber: number;
    keyboardShortcut?: string;
    shipment: OrderShipmentT | null;
    pickUpWaypoint: OrderWaypointT | null;
    dropOffWaypoint: OrderWaypointT | null;
    className?: string;
};

const cx = classNames.bind(styles);

const ShipmentDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const { isReeferTrailer, shipmentNumber, shipment, pickUpWaypoint, dropOffWaypoint, keyboardShortcut, className } =
        props;

    const { t } = useTranslation();

    const poDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.po-number'),
            value: shipment?.purchaseOrderNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'po-number',
        },
    ];

    const brokerWindowAppointmentWarning = (
        <Tooltip
            position={TooltipPositionEnum.topLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent width={150} isCenter theme={TooltipContentThemeEnum.black}>
                    {t('common:order-details.broker-window-appointment-warning')}
                </TooltipContent>
            }
        >
            {(isShow, childrenClassName) => (
                <TimeWindowIcon
                    className={childrenClassName}
                    fillColor={StyleGuideColorsEnum.transparent}
                    strokeColor={StyleGuideColorsEnum.orange}
                />
            )}
        </Tooltip>
    );

    const pickupBrokerAppointmentStatus = checkBrokerAppointmentStatus({
        shouldBrokerWindowAppointment: pickUpWaypoint?.appointmentByBroker,
        originalDateTimeFrom: pickUpWaypoint?.originalDateTimeFrom,
        originalDateTimeTo: pickUpWaypoint?.originalDateTimeTo,
        correctedDateTimeFrom: pickUpWaypoint?.correctedDateTimeFrom,
        correctedDateTimeTo: pickUpWaypoint?.correctedDateTimeTo,
    });

    const pickUpDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(pickUpWaypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('common:order-details.shipment-details.by-type.pickup'),
            value: (
                <LocationLabel
                    format="s1_s2_zip_city_country"
                    location={pickUpWaypoint?.address}
                    separator={ADDRESS_SEPARATOR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'origin',
        },
        ...(pickupBrokerAppointmentStatus.shouldShowCorrectedDateTime
            ? [
                  {
                      icon: null,
                      name: t('common:order-details.shipment-details.columns.origin-time-slots'),
                      value: formatTimeInterval(
                          pickUpWaypoint?.originalDateTimeFrom,
                          pickUpWaypoint?.originalDateTimeTo,
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      testSelector: 'original-date-time',
                      hasBottomBorder: true,
                  },
                  {
                      icon: pickupBrokerAppointmentStatus.shouldBrokerWindowAppointment
                          ? brokerWindowAppointmentWarning
                          : null,
                      name: (
                          <AttentionTextFormatter
                              isActive={pickupBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {t('common:order-details.shipment-details.columns.corrected-time-slots')}
                          </AttentionTextFormatter>
                      ),
                      value: (
                          <AttentionTextFormatter
                              isActive={pickupBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {formatTimeInterval(
                                  pickUpWaypoint?.correctedDateTimeFrom,
                                  pickUpWaypoint?.correctedDateTimeTo,
                              )}
                          </AttentionTextFormatter>
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      testSelector: 'corrected-date-time',
                      hasBottomBorder: true,
                  },
              ]
            : [
                  {
                      icon: pickupBrokerAppointmentStatus.shouldBrokerWindowAppointment
                          ? brokerWindowAppointmentWarning
                          : null,
                      name: (
                          <AttentionTextFormatter
                              isActive={pickupBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {t('common:order-details.shipment-details.columns.time-slots')}
                          </AttentionTextFormatter>
                      ),
                      value: (
                          <WarningTextFormatter isActive={pickupBrokerAppointmentStatus.shouldShowCorrectedDateTime}>
                              {formatTimeInterval(
                                  pickUpWaypoint?.correctedDateTimeFrom || pickUpWaypoint?.originalDateTimeFrom,
                                  pickUpWaypoint?.correctedDateTimeTo || pickUpWaypoint?.originalDateTimeTo,
                              )}
                          </WarningTextFormatter>
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      testSelector: 'date-time',
                      hasBottomBorder: true,
                  },
              ]),
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.company-name'),
            value: pickUpWaypoint?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.contact-name'),
            value: pickUpWaypoint?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.contact-phone'),
            value: pickUpWaypoint?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-phone',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.contact-email'),
            value: pickUpWaypoint?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-email',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.reference-number'),
            value: shipment?.pickupReferenceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'loading-reference',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.comment'),
            value: pickUpWaypoint?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-comment',
        },
    ];

    const dropOffBrokerAppointmentStatus = checkBrokerAppointmentStatus({
        shouldBrokerWindowAppointment: dropOffWaypoint?.appointmentByBroker,
        originalDateTimeFrom: dropOffWaypoint?.originalDateTimeFrom,
        originalDateTimeTo: dropOffWaypoint?.originalDateTimeTo,
        correctedDateTimeFrom: dropOffWaypoint?.correctedDateTimeFrom,
        correctedDateTimeTo: dropOffWaypoint?.correctedDateTimeTo,
    });

    const dropOffDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(dropOffWaypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('common:order-details.shipment-details.by-type.delivery'),
            value: (
                <LocationLabel
                    format="s1_s2_zip_city_country"
                    location={dropOffWaypoint?.address}
                    separator={ADDRESS_SEPARATOR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'origin',
        },
        ...(dropOffBrokerAppointmentStatus.shouldShowCorrectedDateTime
            ? [
                  {
                      icon: null,
                      name: t('common:order-details.shipment-details.columns.origin-time-slots'),
                      value: formatTimeInterval(
                          dropOffWaypoint?.originalDateTimeFrom,
                          dropOffWaypoint?.originalDateTimeTo,
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      testSelector: 'original-date-time',
                      hasBottomBorder: true,
                  },
                  {
                      icon: dropOffBrokerAppointmentStatus.shouldBrokerWindowAppointment
                          ? brokerWindowAppointmentWarning
                          : null,
                      name: (
                          <AttentionTextFormatter
                              isActive={dropOffBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {t('common:order-details.shipment-details.columns.corrected-time-slots')}
                          </AttentionTextFormatter>
                      ),
                      value: (
                          <AttentionTextFormatter
                              isActive={dropOffBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {formatTimeInterval(
                                  dropOffWaypoint?.correctedDateTimeFrom,
                                  dropOffWaypoint?.correctedDateTimeTo,
                              )}
                          </AttentionTextFormatter>
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      testSelector: 'corrected-date-time',
                      hasBottomBorder: true,
                  },
              ]
            : [
                  {
                      icon: dropOffBrokerAppointmentStatus.shouldBrokerWindowAppointment
                          ? brokerWindowAppointmentWarning
                          : null,
                      name: (
                          <AttentionTextFormatter
                              isActive={dropOffBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {t('common:order-details.shipment-details.columns.time-slots')}
                          </AttentionTextFormatter>
                      ),
                      value: (
                          <AttentionTextFormatter
                              isActive={dropOffBrokerAppointmentStatus.shouldBrokerWindowAppointment}
                          >
                              {formatTimeInterval(
                                  dropOffWaypoint?.correctedDateTimeFrom || dropOffWaypoint?.originalDateTimeFrom,
                                  dropOffWaypoint?.correctedDateTimeTo || dropOffWaypoint?.originalDateTimeTo,
                              )}
                          </AttentionTextFormatter>
                      ),
                      emptyValue: t('common:info-table.placeholders.not-specified'),
                      isBoldValue: true,
                      testSelector: 'date-time',
                      hasBottomBorder: true,
                  },
              ]),
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.company-name'),
            value: dropOffWaypoint?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.contact-name'),
            value: dropOffWaypoint?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.contact-phone'),
            value: dropOffWaypoint?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-phone',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.contact-email'),
            value: dropOffWaypoint?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-email',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.reference-number'),
            value: shipment?.dropOffReferenceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'loading-reference',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:order-details.shipment-details.columns.comment'),
            value: dropOffWaypoint?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-comment',
        },
    ];

    const loadDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CargoUnitIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.light}
                    strokeColor={StyleGuideColorsEnum.gray}
                />
            ),
            name: t('common:order-details.shipment-details.columns.units'),
            value: <UnitTypeCount type={getLoadUnitType(shipment?.unitsType)} count={shipment?.maxNumberOfUnits} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'load-units',
        },
        {
            icon: <WeightIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.shipment-details.columns.cargo-weight'),
            value: <UnitTypeCount count={shipment?.weight} type={UnitTypeEnum.kilogramsAbbreviation} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'cargo-weight',
        },
        isReeferTrailer
            ? {
                  icon: <SnowflakeIcon fillColor={StyleGuideColorsEnum.gray} />,
                  name: t('common:order-details.shipment-details.columns.temperature'),
                  value: (
                      <TemperatureModeFormatter
                          lowestTemperature={shipment?.lowestTemperature}
                          highestTemperature={shipment?.highestTemperature}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'temperature',
              }
            : null,
        {
            icon: <CommentIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.shipment-details.columns.description'),
            value: shipment?.description,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'description',
        },
    ];

    return (
        <>
            <Card
                keyboardShortcut={keyboardShortcut}
                titleNode={t('common:order-details.shipment-details.title', {
                    number: shipmentNumber,
                })}
                afterTitleNode={
                    <CargoUnitIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.slate}
                    />
                }
                rightNode={
                    <>
                        {shipment?.warning && <ShipmentWarningPill isSymmetrical warning={shipment?.warning} />}
                        <ShipmentStatusPill status={shipment?.status} isSymmetrical />
                    </>
                }
                className={className}
                hasHeaderBottomBorder
            >
                <div className={cx('content')}>
                    <InfoTable shouldRenderIcons rows={poDetails} className={cx('table', 'table--po')} />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={pickUpDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={dropOffDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable
                        shouldRenderIcons
                        className={cx('table', 'table--load')}
                        rows={loadDetails}
                        testSelector="load-details"
                    />
                </div>
            </Card>
        </>
    );
});

export default ShipmentDetailsCard;
