import { OrderSourceT } from 'common/utils/api/models';

export enum FieldsEnum {
    dictTrailerId = 'dictTrailerId',
    source = 'source',
    pickupAddress = 'pickupAddress',
    dropoffAddress = 'dropoffAddress',
    pickupDates = 'pickupDates',
    pickupTimeWindow = 'pickupTimeWindow',
    dropoffDates = 'dropoffDates',
    dropoffTimeWindow = 'dropoffTimeWindow',
    createDates = 'createDates',
    createdById = 'createdById',
    createdByName = 'createdByName',
}

export type FormValuesT = {
    [FieldsEnum.dictTrailerId]: TrailerDictIdT | null;
    [FieldsEnum.source]: OrderSourceT | null;
    [FieldsEnum.pickupAddress]: string;
    [FieldsEnum.dropoffAddress]: string;
    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.pickupTimeWindow]: number[];
    [FieldsEnum.dropoffDates]: DateRangeT | null;
    [FieldsEnum.dropoffTimeWindow]: number[];
    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.createdById]: UserIdT | null;
    [FieldsEnum.createdByName]: string | null;
};
