import {
    getDateFromDate,
    getEndDayFromDateZero,
    getISOFromDateAndTime,
    getStartDayFromDateZero,
    patchMidnightRelativeTime,
} from 'common/utils/time';
import { FieldsEnum, FormValuesT } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'shipper/layouts/OrdersPage/OrderListPage/query-models';
import isNumber from 'lodash/isNumber';
import { simpleStringFormatter } from 'common/utils/form-formatters';

const getQueryFilters = (values: FormValuesT): QueryFiltersT => {
    const queryFilters: QueryFiltersT = {};

    const dictTrailerId = values[FieldsEnum.dictTrailerId];
    if (dictTrailerId) {
        queryFilters[QueryFiltersKeysEnum.dictTrailerId] = dictTrailerId;
    }

    const source = values[FieldsEnum.source];
    if (source) {
        queryFilters[QueryFiltersKeysEnum.source] = source;
    }

    const pickupAddress = simpleStringFormatter(values[FieldsEnum.pickupAddress]);
    if (pickupAddress) {
        queryFilters[QueryFiltersKeysEnum.origin] = pickupAddress;
    }

    const createdById = values[FieldsEnum.createdById];
    const createdByName = values[FieldsEnum.createdByName];
    if (createdById) {
        queryFilters[QueryFiltersKeysEnum.createdById] = createdById;
        queryFilters[QueryFiltersKeysEnum.createdByName] = createdByName || '';
    }

    const dropoffAddress = simpleStringFormatter(values[FieldsEnum.dropoffAddress]);
    if (dropoffAddress) {
        queryFilters[QueryFiltersKeysEnum.destination] = dropoffAddress;
    }

    const pickupDates = values[FieldsEnum.pickupDates];
    const pickupTimeWindow = values[FieldsEnum.pickupTimeWindow] || [];

    if (pickupDates?.from && isNumber(pickupTimeWindow[0])) {
        queryFilters[QueryFiltersKeysEnum.pickupDateFrom] = getISOFromDateAndTime(
            pickupDates.from,
            pickupTimeWindow[0],
        );
    }

    if (pickupDates?.to && isNumber(pickupTimeWindow[1])) {
        const ms = patchMidnightRelativeTime(pickupTimeWindow[1]);
        queryFilters[QueryFiltersKeysEnum.pickupDateTo] = getISOFromDateAndTime(pickupDates.to, ms);
    }

    const dropoffDates = values[FieldsEnum.dropoffDates];
    const dropoffTimeWindow = values[FieldsEnum.dropoffTimeWindow] || [];

    if (dropoffDates?.from && isNumber(dropoffTimeWindow[0])) {
        queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] = getISOFromDateAndTime(
            dropoffDates.from,
            dropoffTimeWindow[0],
        );
    }

    if (dropoffDates?.to && isNumber(dropoffTimeWindow[1])) {
        const ms = patchMidnightRelativeTime(dropoffTimeWindow[1]);
        queryFilters[QueryFiltersKeysEnum.dropOffDateTo] = getISOFromDateAndTime(dropoffDates.to, ms);
    }

    const createDates = values[FieldsEnum.createDates];

    if (createDates?.from) {
        const date = getDateFromDate(createDates.from);
        queryFilters[QueryFiltersKeysEnum.createdDateFrom] = getStartDayFromDateZero(date);
    }

    if (createDates?.to) {
        const date = getDateFromDate(createDates.to);
        queryFilters[QueryFiltersKeysEnum.createdDateTo] = getEndDayFromDateZero(date);
    }

    return queryFilters;
};

export default getQueryFilters;
