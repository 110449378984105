import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './OrderDetailsPage.scss';

import { useParams } from 'react-router-dom';
import GoogleMapContext from 'common/contexts/google-map-context';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrder } from 'common/store/order-details/actions';
import { selectOrder, selectOrderRequestStatus } from 'common/store/order-details/selectors';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import OrderDetails from 'shipper/layouts/OrdersPage/OrderDetailsPage/OrderDetails/OrderDetails';
import history, { goBackIfHasRePath } from 'common/utils/history';
import OrderStatusPill from 'common/components/status-pill/OrderStatusPill/OrderStatusPill';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import TabsLabel from 'common/components/TabsLabel/TabsLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import TabLabel from 'common/components/TabsLabel/TabLabel/TabLabel';
import { subscribe } from 'common/utils/notification-pub-sub';
import { NotificationActionEnum } from 'common/store/notifications/models';
import { fetchTracksByOrder } from 'common/store/asset-track/actions';
import { getAssetTrackHashByOrderId } from 'common/store/asset-track/utils';
import { selectAssetTrackPoints, selectAssetTrackRequest } from 'common/store/asset-track/selectors';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';

import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import { ApiOrderStatusT, OrderStatusEnum, OrderWaypointTypeEnum } from 'common/utils/api/models';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CloseIcon from 'common/icons/CloseIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import ConfirmCancelDialog, { ConfirmCancelDialogDataT } from './ConfirmCancelDialog/ConfirmCancelDialog';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { selectPermissions } from 'common/store/auth/selectors';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'shipper/layouts/SideBars/hooks';
import { logWarning } from 'common/utils/logger';
import SideBars from 'shipper/layouts/SideBars/SideBars';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { urlFactory } from 'shipper/utils/urls';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import MultiPointOrderRouteMap, {
    MultiPointOrderRouteMapPropsT,
} from 'common/components/MultiPointOrderRouteMap/MultiPointOrderRouteMap';
import { selectStateMachineItemState } from 'common/store/state-machine/selectors';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import find from 'lodash/find';

const cx = classNames.bind(styles);

type PropsT = {};

const OrderDetailsPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);

    const params = useParams<{ orderId: string }>();
    const { orderId } = params;

    const order = useSelector(selectOrder(orderId));

    const trailerTour = find(order?.tours, (tour) => !!tour?.trailer);
    const trailerRoutePolylineId = trailerTour?.polylineId || null;

    const truckTour = find(order?.tours, (tour) => !!tour?.truck);
    const truckRoutePolylineId = truckTour?.polylineId || null;

    const payloadPolylineId = order?.polylineId;

    const polylineIds = useMemo(() => {
        return [trailerRoutePolylineId, truckRoutePolylineId, payloadPolylineId].filter(isNonNil);
    }, [trailerRoutePolylineId, truckRoutePolylineId, payloadPolylineId]);

    const descriptor = useMemo((): StateMachineEntityDescriptorT | null => {
        if (!order?.id) {
            return null;
        }

        return {
            entityType: 'ORDER',
            entityId: order.id,
        };
    }, [order]);

    const { updateRequest } = useSelector(selectStateMachineItemState<ApiOrderStatusT>(descriptor));

    useRouteGeometry(polylineIds);

    const orderRequestStatus = useSelector(selectOrderRequestStatus(orderId));

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchOrder(orderId));

        return subscribe([NotificationActionEnum.ORDER_STATUS_CHANGED], () => {
            dispatch(fetchOrder(orderId, { isForceUpdate: true }));
        });
    }, [orderId]);

    const closeOrderDetails = () => {
        goBackIfHasRePath(() => {
            history.push(urlFactory.orderList());
        });
    };

    const firstShipment = order?.shipments?.[0];

    const trailerVehicleId = order?.tours?.[0]?.trailer?.id;

    React.useEffect(() => {
        dispatch(fetchTracksByOrder(orderId));
    }, [orderId]);

    const hash = getAssetTrackHashByOrderId(orderId, trailerVehicleId);
    const trailerTrack = useSelector(selectAssetTrackPoints(hash));
    const trailerTrackRequest = useSelector(selectAssetTrackRequest(hash));

    const payloadRoutingGeometryState = useSelector(selectRoutingGeometryState(payloadPolylineId));
    const trailerRoutingGeometryState = useSelector(selectRoutingGeometryState(trailerRoutePolylineId));
    const truckRoutingGeometryState = useSelector(selectRoutingGeometryState(truckRoutePolylineId));

    const mapPoints = useMemo((): MultiPointOrderRouteMapPropsT['points'] => {
        const waypoints = order?.waypoints;
        if (!waypoints) {
            return [];
        }

        return waypoints?.map((waypoint) => {
            return {
                longitude: waypoint.address?.longitude,
                latitude: waypoint.address?.latitude,
                driveThru: waypoint?.type === OrderWaypointTypeEnum.driveThroughWaypoint,
                index: waypoint?.index,
            };
        });
    }, [order]);

    const [activeIndexTab, setActiveIndexTab] = React.useState<number>(0);
    const tabs = [
        {
            label: t('common:order-details.tabs.map'),
            node: (
                <MultiPointOrderRouteMap
                    key={order?.id}
                    withRouteTrack
                    points={mapPoints}
                    loadStatus={firstShipment?.status}
                    route={payloadRoutingGeometryState.data}
                    routeRequest={payloadRoutingGeometryState.requestStatus}
                    truckRoute={truckRoutingGeometryState.data}
                    truckRouteRequest={truckRoutingGeometryState.requestStatus}
                    trailerRoute={trailerRoutingGeometryState.data}
                    trailerRouteRequest={trailerRoutingGeometryState.requestStatus}
                    trailerTrack={trailerTrack}
                    trailerTrackRequest={trailerTrackRequest}
                />
            ),
        },
        // TODO wait backend
        // {
        //     label: t('common:order-details.tabs.activity'),
        //     node: <OrderHistory />,
        // },
    ];

    const confirmCancelConfirmation = useModalDialog<ConfirmCancelDialogDataT>();

    useCloseModalDialogAfterRequest(updateRequest, [confirmCancelConfirmation]);

    const options: Array<DropdownControlOptionT | null> = [
        permissions.canCancelOrder && order?.canBeCanceled
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                          label={t('common:order-details.actions.cancel')}
                      />
                  ),
                  onSelect: () => {
                      confirmCancelConfirmation.setData({
                          id: order?.id,
                          number: order?.number,
                      });
                  },
              }
            : null,
    ];

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchOrder(orderId, { isForceUpdate: false }));
    }, [orderId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        if (!descriptor) {
            return;
        }

        dispatch(fetchOrder(descriptor.entityId, { isForceUpdate: true }));
    }, [descriptor]);
    useChannelSubscribe(stateMachineRefreshChannel, refreshPageHandler);

    const handleConfirmCancel = (data: ConfirmCancelDialogDataT) => {
        if (!order || !descriptor) {
            return;
        }

        dispatch(
            updateEntityStatus({
                descriptor,
                update: {
                    status: OrderStatusEnum.canceled,
                    entityId: data.id,
                    entityType: 'ORDER',
                },
            }),
        );
    };

    const isShowLineLoader = orderRequestStatus.loading && !!order;

    const openLeftSidebar = useOpenLeftSidebar();

    const goToUserDetails = React.useCallback(
        (userId: UserIdT | null) => {
            if (!userId || !partnerId || !partnerType) {
                logWarning('failed to open user details in order details, empty userId or partnerId or partnerType');
                return;
            }

            openLeftSidebar(
                {
                    type: CommonSidebarsTypeEnum.contact,
                    partnerId,
                    partnerType,
                    userId,
                },
                {
                    isForceShowBackAction: true,
                },
            );
        },
        [partnerId, partnerType],
    );

    return (
        <>
            <GoogleMapContext.Provider value={googleMapsContextValue}>
                <PageTitle
                    title={t('page-titles.order-details', {
                        number: order?.number || '',
                    })}
                />
                <HorizontalColumnsLayout
                    testSelector="order-details"
                    className={cx('wrap')}
                    leftColumnTitle={t('common:order-details.main-tabs.details')}
                    leftColumnNode={
                        <SideBarLayoutContextWrap>
                            {updateRequest.loading && <LoaderOverlay />}
                            <HeaderSideBarLayout>
                                <HeaderSideBarContent
                                    testSelector="order-details"
                                    title={
                                        order
                                            ? t('common:order-details.title', {
                                                  number: order.number || '',
                                              })
                                            : null
                                    }
                                    afterTitleNode={
                                        order ? (
                                            <OrderStatusPill
                                                status={order.status}
                                                isArchived={order.archived}
                                                isSymmetrical
                                            />
                                        ) : null
                                    }
                                    onClose={closeOrderDetails}
                                    rightNode={
                                        <DropdownControl
                                            options={options}
                                            renderTrigger={(isActive, onClick) => (
                                                <TransparentTrigger
                                                    isPressed={isActive}
                                                    onClick={onClick}
                                                    leftIcon={<MoreIcon />}
                                                    reflectionTheme={ReflectionThemeEnum.light}
                                                />
                                            )}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                        />
                                    }
                                />
                                <HeaderSideBarLoader isShow={isShowLineLoader} />
                            </HeaderSideBarLayout>
                            <SideBarLayout>
                                {order ? (
                                    <OrderDetails order={order} goToUserDetails={goToUserDetails} />
                                ) : (
                                    <EntitySidebarSkeleton />
                                )}
                            </SideBarLayout>
                            {!order && <SideBarLoader />}
                        </SideBarLayoutContextWrap>
                    }
                    rightColumnTitle={t('common:order-details.main-tabs.info')}
                    rightColumnNode={
                        <div className={cx('tabs-wrap')}>
                            <TabsLabel className={cx('tabs')}>
                                {tabs.map((tab, tabIndex) => (
                                    <TabLabel
                                        key={tabIndex}
                                        theme={TabsThemesEnum.light}
                                        onClick={() => {
                                            setActiveIndexTab(tabIndex);
                                        }}
                                        isActive={tabIndex === activeIndexTab}
                                    >
                                        {tab.label}
                                    </TabLabel>
                                ))}
                            </TabsLabel>
                            <div className={cx('tab')}>{tabs[activeIndexTab].node}</div>
                        </div>
                    }
                />
                <ConfirmCancelDialog
                    requestStatus={updateRequest}
                    data={confirmCancelConfirmation.data}
                    onCancel={confirmCancelConfirmation.onCancel}
                    onClose={confirmCancelConfirmation.onClose}
                    onConfirm={handleConfirmCancel}
                />
            </GoogleMapContext.Provider>
            <SideBars />
        </>
    );
});

export default OrderDetailsPage;
