import { FieldsEnum, FormValuesT } from './constants';
import { ALL_DAY_RANGE } from 'common/constants';
import { QueryFiltersT } from 'shipper/layouts/OrdersPage/OrderListPage/query-models';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.dictTrailerId]: null,
        [FieldsEnum.source]: null,
        [FieldsEnum.pickupAddress]: '',
        [FieldsEnum.pickupTimeWindow]: ALL_DAY_RANGE,
        [FieldsEnum.dropoffAddress]: '',
        [FieldsEnum.dropoffTimeWindow]: ALL_DAY_RANGE,
        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.dropoffDates]: null,
        [FieldsEnum.createDates]: null,
        [FieldsEnum.createdByName]: null,
        [FieldsEnum.createdById]: null,
    };

    // if (queryFilters[QueryFiltersKeysEnum.pickupDateFrom]) {
    //     const momentDate = moment(queryFilters[QueryFiltersKeysEnum.pickupDateFrom]).utcOffset(utcOffset);
    //
    //     const date = new Date(momentDate.format('YYYY-MM-DD'));
    //     values[FieldsEnum.pickupDates] = {
    //         from: date,
    //         to: null,
    //     };
    //
    //     const relativeTime = momentDate.valueOf() - momentDate.clone().startOf('day').valueOf();
    //     values[FieldsEnum.pickupTimeWindow] = [relativeTime];
    // }
    //
    // if (queryFilters[QueryFiltersKeysEnum.pickupDateTo]) {
    //     const momentDate = moment(queryFilters[QueryFiltersKeysEnum.pickupDateTo]).utcOffset(utcOffset);
    //
    //     const date = new Date(momentDate.format('YYYY-MM-DD'));
    //     values[FieldsEnum.pickupDates] = {
    //         from: null,
    //         ...values[FieldsEnum.pickupDates],
    //         to: date,
    //     };
    //
    //     const relativeTime = momentDate.valueOf() - momentDate.clone().startOf('day').valueOf();
    //     values[FieldsEnum.pickupTimeWindow].push(relativeTime);
    // }

    // if (queryFilters[QueryFiltersKeysEnum.dropOffDateFrom]) {
    //     const momentDate = moment(queryFilters[QueryFiltersKeysEnum.dropOffDateFrom]).utcOffset(utcOffset);
    //
    //     const date = new Date(momentDate.format('YYYY-MM-DD'));
    //     values[FieldsEnum.dropoffDates] = {
    //         from: date,
    //         to: null,
    //     };
    //
    //     const relativeTime = momentDate.valueOf() - momentDate.clone().startOf('day').valueOf();
    //     values[FieldsEnum.dropoffTimeWindow] = [relativeTime];
    // }
    //
    // if (queryFilters[QueryFiltersKeysEnum.dropOffDateTo]) {
    //     const momentDate = moment(queryFilters[QueryFiltersKeysEnum.dropOffDateTo]).utcOffset(utcOffset);
    //
    //     const date = new Date(momentDate.format('YYYY-MM-DD'));
    //     values[FieldsEnum.dropoffDates] = {
    //         from: null,
    //         ...values[FieldsEnum.dropoffDates],
    //         to: date,
    //     };
    //
    //     const relativeTime = momentDate.valueOf() - momentDate.clone().startOf('day').valueOf();
    //     values[FieldsEnum.dropoffTimeWindow].push(relativeTime);
    // }
    //
    // if (queryFilters[QueryFiltersKeysEnum.createdDateFrom]) {
    //     const momentDate = moment(queryFilters[QueryFiltersKeysEnum.createdDateFrom]).utcOffset(utcOffset);
    //
    //     const date = new Date(momentDate.format('YYYY-MM-DD'));
    //     values[FieldsEnum.createDates] = {
    //         from: date,
    //         to: null,
    //     };
    // }
    //
    // if (queryFilters[QueryFiltersKeysEnum.createdDateTo]) {
    //     const momentDate = moment(queryFilters[QueryFiltersKeysEnum.createdDateTo]).utcOffset(utcOffset);
    //
    //     const date = new Date(momentDate.format('YYYY-MM-DD'));
    //     values[FieldsEnum.createDates] = {
    //         from: null,
    //         ...values[FieldsEnum.createDates],
    //         to: date,
    //     };
    // }

    return values;
};

export default getInitialValues;
