import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { Trans, useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { formatDateRangeFilterValue, pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';
import { OrderSourceEnum, OrderSourceT } from 'common/utils/api/models';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const sourceMapT: Record<OrderSourceT, string> = {
    [OrderSourceEnum.internal]: 'orders-table.filters.sidebar.source-type.internal',
    [OrderSourceEnum.imported]: 'orders-table.filters.sidebar.source-type.imported',
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.origin', {
                        value: queryFilters[QueryFiltersKeysEnum.origin],
                    });
                },
                theme: FilterPillThemeEnum.brandAccent,
                clearKeys: [QueryFiltersKeysEnum.origin],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.destination', {
                        value: queryFilters[QueryFiltersKeysEnum.destination],
                    });
                },
                theme: FilterPillThemeEnum.brandAccent,
                clearKeys: [QueryFiltersKeysEnum.destination],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.search-text', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                theme: FilterPillThemeEnum.brandAccent,
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
            {
                render: () => {
                    return t('orders-table.filter-labels.spot');
                },
                theme: FilterPillThemeEnum.tomatoRed,
                clearKeys: [QueryFiltersKeysEnum.onlySpotOrders],
            },
            {
                render: () => {
                    return t('orders-table.filter-labels.contacted');
                },
                theme: FilterPillThemeEnum.tomatoRed,
                clearKeys: [QueryFiltersKeysEnum.onlyContractedOrders],
            },
            {
                renderIcon: (queryFilters) => {
                    const name = queryFilters[QueryFiltersKeysEnum.createdByName] || '';

                    return pillIconRendererMap.avatar(name, isCompact);
                },
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.created-by', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByName],
                    });
                },
                theme: FilterPillThemeEnum.charcoal,
                clearKeys: [QueryFiltersKeysEnum.createdById, QueryFiltersKeysEnum.createdByName],
            },
            {
                render: (queryFilters) => {
                    const trailerDictId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="orders-table.filter-labels.trailer-type"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={trailerDictId} />,
                            }}
                        />
                    );
                },
                theme: FilterPillThemeEnum.blazeOrange,
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('orders-table.filter-labels.pickup', {
                        value: formatDateRangeFilterValue(pickupDateFrom, pickupDateTo),
                    });
                },
                theme: FilterPillThemeEnum.charcoal,
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('orders-table.filter-labels.drop-off', {
                        value: formatDateRangeFilterValue(dropOffDateFrom, dropOffDateTo),
                    });
                },
                theme: FilterPillThemeEnum.charcoal,
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
            {
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('orders-table.filter-labels.created', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                theme: FilterPillThemeEnum.charcoal,
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const source = queryFilters[QueryFiltersKeysEnum.source] as OrderSourceT;
                    return t('orders-table.filter-labels.source', {
                        value: t(sourceMapT[source]),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.source],
            },
        ],
        [t],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;
