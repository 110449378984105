import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ExpiredDocumentCard.scss';
import { Trans } from 'react-i18next';
import { ApiDocumentTypeT } from 'common/utils/api/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import AsyncDictDocumentNameLabel from 'common/components/AsyncDictDocumentNameLabel/AsyncDictDocumentNameLabel';

const cx = classNames.bind(styles);

type PropsT = {
    documentType: ApiDocumentTypeT;
};

const ExpiredDocumentCard: React.FC<PropsT> = React.memo((props) => {
    const { documentType } = props;

    const { partnerType } = usePartnerContext();

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="common:notifications.document-expired"
                components={{
                    documentName: (
                        <AsyncDictDocumentNameLabel
                            className={cx('document-name')}
                            partnerType={partnerType}
                            documentType={documentType}
                        />
                    ),
                }}
            />
        </div>
    );
});

export default ExpiredDocumentCard;
